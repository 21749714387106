import React from 'react'
import { Link } from 'gatsby'
import '../../static/styles/knowledge-pages.css'

const KnowledgePages = ({ heading, items, isAuthorDetail }) =>
  items && items.length > 0 ? (
    <section
      className={`knowledge-pages${
        isAuthorDetail && ' knowledge-pages--author-detail'
      }`}
    >
      <h2 className="knowledge-pages__heading">{heading}</h2>
      <div
        className={`knowledge-pages__items${
          isAuthorDetail && ' knowledge-pages__items--author-detail'
        }`}
      >
        {items.map(({ name, slug, content }, id) => (
          <article
            key={id}
            className="knowledge-pages__item"
            style={{ borderColor: content.themeColor }}
          >
            <span className="knowledge-pages__overline">Article</span>
            <h3 className="knowledge-pages__subheading">
              <Link to={slug}>{name}</Link>
            </h3>
            {content.snippet && <p className="knowledge-pages__text">{content.snippet}</p>}
          </article>
        ))}
      </div>
    </section>
  ) : (
    ''
  )

export default KnowledgePages
