import React from 'react'
import { Link } from 'gatsby'
import Image from "utils/Image"
import Breadcrumb from './Breadcrumb'
import '../../static/styles/knowledge-banner.css'

const KnowledgeBanner = ({
  breadcrumbs,
  heading,
  updatedAt,
  author,
  themeColor,
}) => (
  <section className="knowledge-banner" style={{ backgroundColor: "#1b203b" }}>
    <div className="container">
      <div className="knowledge-banner__inner">
        <div className="knowledge-banner__breadcrumbs">
          <Breadcrumb items={[...breadcrumbs]} />
        </div>
        <h1 className="knowledge-banner__heading">{heading}</h1>
        <div className="knowledge-banner__meta">
          <p className="knowledge-banner__updated-at">
            Updated at: {updatedAt}
          </p>
          {author && (
            <Link
              to={`/authors/${author.slug}`}
              className="knowledge-banner__author"
            >
              <div className="image-wrapper">
                <Image img={author?.content?.avatar} alt={author.name} />
              </div>
              <p>{author.name}</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default KnowledgeBanner 
