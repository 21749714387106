import React from 'react'
import { renderToString } from 'react-dom/server';
import { useStaticQuery, graphql } from 'gatsby'
import KnowledgeNav from './KnowledgeNav'
import KnowledgePages from './KnowledgePages'
import '../../static/styles/knowledge-main.css'
import RichTextRenderer from 'utils/RichTextRenderer'
import { storyblokEditable } from "gatsby-source-storyblok"
import FooterBanner from '../components/FooterBanner'

const KnowledgeMain = ({
  nav,
  data,
  content,
  children,
  themeColor,
  locationData,
}) => {
  const { authors } = useStaticQuery(graphql`
    query KnowledgeMainQuery {
      authors: allStoryblokEntry(
        filter: { field_component: { eq: "author" } }
      ) {
        nodes {
          slug
          name
          content
        }
      }
    }
  `)

  content = renderToString(
    (<RichTextRenderer data={content} />)
  )
  content = content
    .replaceAll(
      /\[quote text=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) author=(?:&ldquo;|“)(.*?)(?:&rdquo;|”)]/g,
      `
      <figure class="content-quote" style="border-left-color: ${themeColor}">
        <blockquote>
          <p>$1</p>
        </blockquote>
        <figcaption>$2</figcaption>
      </figure>
      `
    )
    .replaceAll(
      /\[snippet title=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) text=(?:&ldquo;|“)(.*?)(?:&rdquo;|”)]/g,
      `
      <aside class="content-snippet" style="border-left-color: ${themeColor}">
        <h2>$1</h2>
        <p>$2</p>
      </aside>
      `
    )
    .replaceAll(
      /\[cta title=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) text=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) button=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) url=(?:&ldquo;|“)(.*?)(?:&rdquo;|”)]/g,
      `
      <aside class="content-cta"  style="background-color: ${themeColor}">
        <h2>$1</h2>
        <p>$2</p>
        <a href="$4" class="btn btn-primary purple-btn">$3</a>
      </aside>
      `
    )
    .replaceAll(
      /\[callout title=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) text=(?:&ldquo;|“)(.*?)(?:&rdquo;|”) url=(?:&ldquo;|“)(.*?)(?:&rdquo;|”)]/g,
      `
      <aside class="content-callout" style="border-top-color: ${themeColor}">
        <p>$1: <a href="$3">$2</a></p>
      </aside>
      `
    )

    authors.nodes.map(({ slug, name, content: authorContent }) => {
      authorContent = JSON.parse(authorContent)
      content = content.replaceAll(
        `<figcaption>${slug}</figcaption>`,
        `
        <figcaption>
          ${
            authorContent &&
            authorContent.avatar &&
            `<img class="user-highlight-avatar" src="${authorContent.avatar?.filename}?w=30&h=30" width=30 height=30 alt="${name}" loading="lazy" />`
          }
          ${name}
        </figcaption>
        `
      )
    })

  return (
    <div {...storyblokEditable(data)} className="knowledge-main">
      <div className="container">
        <div className="knowledge-main__inner">
          <KnowledgeNav nav={nav} locationData={locationData} />
          <div className="knowledge-main__content">
            <div className="blog-post">
              <article
                className="post-content"
                dangerouslySetInnerHTML={{ __html: content }}
              >
              </article>
            </div>
            <KnowledgePages heading="More Articles" items={children} />
            <FooterBanner />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowledgeMain
