import React, { useState, useEffect } from 'react'
import { useStoryblokState } from "gatsby-source-storyblok"
import { Link } from 'gatsby'
import '../../static/styles/knowledge-nav.css'

const KnowledgeNavItemButton = ({ children, ...props }) => (
  <button {...props}>{children}</button>
)

const KnowledgeNavItem = ({
  title,
  nav_title,
  hubSlug,
  pageSlug,
  children,
  locationData,
}) => {
  const [showSubList, setShowSubList] = useState(false)

  useEffect(() => {
    if (locationData) {
      if (locationData.pathname.startsWith(`/${hubSlug}/${pageSlug}`)) {
        setShowSubList(true)
      }
    }
  }, [locationData, hubSlug, pageSlug])

  const LinkComponent =
    children && children.length > 0 ? KnowledgeNavItemButton : Link

  return (
    <li className="knowledge-nav__item">
      <LinkComponent
        {...(children && children.length > 0
          ? {
              className: `knowledge-nav__link knowledge-nav__link--has-children${
                !showSubList ? ' knowledge-nav__link--has-children--hidden' : ''
              }`,
              onClick: () => setShowSubList(!showSubList),
            }
          : {
              to: `/${hubSlug}/${pageSlug}`,
              className: 'knowledge-nav__link',
              activeClassName: 'knowledge-nav__link--active',
            })}
      >
        {nav_title || title}
      </LinkComponent>
      {children && children.length > 0 && (
        <ul
          className={`knowledge-nav__list knowledge-nav__list--sub${
            !showSubList ? ' knowledge-nav__list--sub--hide' : ''
          }`}
        >
          <li className="knowledge-nav__item knowledge-nav__item--sub">
            <Link
              to={`/${hubSlug}/${pageSlug}`}
              className="knowledge-nav__link"
              activeClassName="knowledge-nav__link--active"
            >
              {nav_title || title} Overview
            </Link>
          </li>
          {children.map(({ name, slug, content }, id) => (
            <li
              key={id}
              className="knowledge-nav__item knowledge-nav__item--sub"
            >
              <Link
                to={`/${hubSlug}/${pageSlug}/${slug}`}
                className="knowledge-nav__link"
                activeClassName="knowledge-nav__link--active"
              >
                {content.nav_title || name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

const KnowledgeNav = ({
  nav: {
    hub,
    allSections,
  },
  locationData,
}) => {
  const [displayNav, setDisplayNav] = useState(false)

  return (
    <div>
      <nav className="knowledge-nav">
        <button
          className="knowledge-nav__btn"
          onClick={() => setDisplayNav(!displayNav)}
        >
          Menu
        </button>
        <div
          className={`knowledge-nav__inner${
            !displayNav ? ' knowledge-nav__inner--hide' : ''
          }`}
        >
          <p className="knowledge-nav__heading">
            <Link to={`/${hub.slug}`}>{hub.content.nav_title || hub.name}</Link>
          </p>
          {hub.content.sections &&
            hub.content.sections.map(({ name, slug }, id) => {
              const section = allSections.nodes.find((item) => item.slug === slug)
              let story = useStoryblokState(section, { resolveRelations: ["knowledgesection.pages"] })

              return (
                <section key={id} className="knowledge-nav__section">
                  <p className="knowledge-nav__subheading">{name}</p>
                  <ul className="knowledge-nav__list">
                    {story.content.pages.map(
                      (
                        {
                          name,
                          slug: pageSlug,
                          content: { nav_title, children },
                        },
                        id
                      ) => (
                        <KnowledgeNavItem
                          key={id}
                          title={name}
                          nav_title={nav_title}
                          hubSlug={hub.slug}
                          pageSlug={pageSlug}
                          children={children}
                          locationData={locationData}
                        />
                      )
                    )}
                  </ul>
                </section>
              )
            })}
        </div>
      </nav>
    </div>
  )
}

export default KnowledgeNav
