import React from 'react'
import { Link } from 'gatsby'

import '../../static/styles/breadcrumb.css'

export default ({ items }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {items.map((item) => (
        <li className="breadcrumb-item" key={item.name}>
          <Link title={item.name} to={`/${item.path}`} activeClassName="active">
            {item.name}
          </Link>
        </li>
      ))}
    </ol>
  </nav>
)
